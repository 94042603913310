<template>
    <div class="bottom-drawer" ref="drawerRef">
        <div id="drawerContent" class="drawer-body" ref="drawerInnerRef">
            <slot/>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { createAnimation } from '@ionic/vue';
import "@/assets/scss/ProjectHeader.scss"
import '@/assets/scss/BottomDrawer.scss';

export default defineComponent({
    name: 'BottomDrawer',
    props: {
        page: {
            type: String
        }, 
        project: {
            type: Object,
            default: () => {}
        }
    },
    methods: {
    },
    async mounted() {
        const drawer = this.$refs.drawerRef;
        const drawerHeader = this.$refs.drawerHeaderRef;
        const drawerInner = this.$refs.drawerInnerRef;

        const animationBottomUp = createAnimation()
            .addElement(drawer)
            .duration(500)
            .easing('ease-in-out')
            .keyframes([
                {offset: 0, transform: `translateY(100%)`},
                {offset: 1, transform: 'translateY(0)'}
            ]);
        await animationBottomUp.play();

        const drawerInnerFadeIn = createAnimation()
            .addElement(drawerInner)
            .fill('none')
            .duration(300)
            .easing('ease')
            .afterStyles({
                opacity: 1,
                transform: 'scale(1)'
            })
            .keyframes([
                {offset: 0, transform: 'scale(0.8)', opacity: '0'},
                {offset: 0.5, transform: 'scale(0.9)', opacity: '0.6'},
                {offset: 1, transform: 'scale(1)', opacity: '1'}
            ]);

        const drawerHeaderFadeIn = createAnimation()
            .addElement(drawerHeader)
            .fill('none')
            .duration(300)
            .easing('ease')
            .afterStyles({
                opacity: 1,
                transform: 'scale(1)'
            })
            .keyframes([
                {offset: 0, transform: 'scale(0.9)', opacity: '0'},
                {offset: 0.5, transform: 'scale(0.9)', opacity: '0.6'},
                {offset: 1, transform: 'scale(1)', opacity: '1'}
            ]);

        await drawerHeaderFadeIn.play();
        await drawerInnerFadeIn.play();
    }
});
</script>
