<template>
    <ion-page>
        <div v-if="!verified">
            loading...
        </div>
        <div>
            geverifieerd, je kun nu chatten
        </div>
    </ion-page>
</template>

<script>
    import { defineComponent } from 'vue';
    import gql from 'graphql-tag';
    import { IonPage } from '@ionic/vue'


    export default defineComponent({
        name: 'login',
        components: {
            IonPage
        },
        data() {
            return {
                verified: false,
                email: this.$route.params.email,
                user: JSON.parse(localStorage.getItem('user')) || {}
            }
        },
        computed: {
            loginToken() {
                let { loginToken } = this.$route.params
                let token = ''
                loginToken.forEach((item, index) => {
                    if (index+1 === loginToken.length) {
                        token += `${item}`
                    } else token += `${item}/`
                })
                return token
            },
            redirect() {
                return this.loginToken.split(':')
            }
        },
        mounted() {
            this.$apollo.mutate({
                mutation: gql`
                    mutation {
                        user_passwordless_login_verify(email: "${this.email}", loginToken: "${this.loginToken}") {
                            location
                            authPayload {
                                jwt
                                user {
                                    id
                                    email
                                    firstName
                                    lastName
                                }
                            }
                        }
                    }
                `
            })
            .then(result => {
                let { authPayload, location } = result.data.user_passwordless_login_verify;
                const verifiedUser = authPayload.user;
                const newUser = { ...this.user, ...verifiedUser }
                localStorage.setItem('jwt', authPayload.jwt)
                localStorage.setItem('user', JSON.stringify(newUser))
                this.verified = true
                this.$router.push(location)
            })
        },
    });
</script>
