<template>
    <div class="page">
        <div class="project-wrapper">

            <ion-segment ref="categoryButtons" class="project-detail-categories" scrollable="true" v-model="panels[activeSlideIndex]">
                <ion-segment-button v-for="(panel, index) in panels" :key="panel" :value="panel" :id="`slide-button-${index}`" @click="selectPanel(index)">
                    <ion-label>{{ panel }}</ion-label>
                </ion-segment-button>
            </ion-segment>

            <swiper
                v-if="project"
                :slides-per-view="1"
                :space-between="0"
                @slideChange="onSlideChange"
                class="swiper"
                ref="aboutSwiper"
                :class="{ reserveFollowButtonSpace: !isFollowing }">
                <swiper-slide v-if="panels && panels.includes('Algemeen')" class="panel general-category">
                    <ion-grid class="general-info">
                        <ion-row class="about-row" v-if="project.about">
                            <ion-col>
                                <span>Over dit project</span>
                                <p class="about-text" v-html="project.about"></p>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col v-if="projectLength">
                                <ion-label>
                                    <span>Duur</span>
                                    <h5>{{ projectLength }}</h5>
                                </ion-label>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </swiper-slide>

                <swiper-slide v-if="panels && panels.includes('Planning')" class="panel planning-timeline">
                    <ul class="timeline-list" v-if="project?.omgevingsapp?.phases?.length">
                        <li class="d-flex" v-for="(phase, index) in project.omgevingsapp.phases" :key="index">
                            <div class="timeline-date">
                                <span>{{ getShortMonth(phase.from) }}</span>
                                <span>{{ getDay(phase.from) }}</span>
                            </div>

                            <div class="timeline-detail" :class="getPhaseState(phase)">
                                <h6>{{ phase.name }}</h6>
                                <p v-if="phase.description">
                                    {{ phase.description }}
                                </p>
                            </div>
                        </li>
                        <svg class="flag-icon" viewBox="0 0 512 512"><path d="M80 464V68.14a8 8 0 014-6.9C91.81 56.66 112.92 48 160 48c64 0 145 48 192 48a199.53 199.53 0 0077.23-15.77 2 2 0 012.77 1.85v219.36a4 4 0 01-2.39 3.65C421.37 308.7 392.33 320 352 320c-48 0-128-32-192-32s-80 16-80 16" fill="none" :stroke="flagColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32"/></svg>
                    </ul>
                </swiper-slide>

                <swiper-slide v-if="panels && panels.includes('Documenten')" class="panel documentation">
                    <h6>Documenten</h6>
                    <ion-list lines="none">
                        <!-- <ion-item>
                            <ion-avatar slot="start" class="d-flex ion-justify-content-center ion-align-items-center">
                                <span class="ion-text-uppercase">pdf</span>
                            </ion-avatar>
                            <ion-label>
                                <h5>Bewonersbrief.pdf</h5>
                                <p>
                                    <span>24 Oktober 2021</span>
                                    <span> | </span>
                                    <span>128mb</span>
                                </p>
                            </ion-label>
                        </ion-item> -->
                    </ion-list>
                </swiper-slide>

                <swiper-slide v-if="panels && panels.includes('FAQ')" class="panel faq">
                    <div class="questions-container" v-if="project.omgevingsapp && project.omgevingsapp.FAQ">
                        <div class="question" v-for="(question, index) in project.omgevingsapp.FAQ" :key="index" @click="selectAnswerIndex(index)">
                            <h5>{{ question.title }}</h5>
                            <span class="answer-span" :id="`answer-${index}-span`" :class="{ show: showAnswerIndex == index }">{{ question.description }}</span>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
            

            <!-- <project-send-messages :project="project" v-if="showFollowOptions" @nextStep="handleNextStep" @cancelFollow="handleCancelFollow"></project-send-messages>
            <project-user-relationship :project="project" :selectedFollowOptions="selectedFollowOptions" v-if="showFollowConfiguration" @formCompleted="handleFormCompleted"></project-user-relationship> -->
        </div>
        
        <ion-button v-if="!isFollowing" class="btn btn-primary follow-project-button" @click="handleFollowProject">Project volgen</ion-button>
    </div>
</template>
<script>
import { parseTimeToString } from '@/helpers/utils.js';
import { defineComponent } from 'vue';
import {
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    // IonItem,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    // IonAvatar,
    IonButton
} from '@ionic/vue';
import '@/assets/scss/AppHeader.scss';
import '@/assets/scss/About.scss'

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';

export default defineComponent({
    name: 'ProjectDetail',
    components: {
        IonGrid,
        IonRow,
        IonCol,
        IonList,
        IonSegment,
        IonSegmentButton,
        IonLabel,
        IonButton,
        Swiper,
        SwiperSlide,
    },
    props: {
        activePageIndex: {
            type: Number,
            required: true
        },
        project: {
            type: Object,
        },
        isFollowing: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')) || {},
            // showFollowOptions: false,
            // showFollowConfiguration: false,
            projectLength: '',
            selectedFollowOptions: [],
            panels: [
                'Algemeen',
                'Planning',
                // 'Documenten',
                'FAQ'
            ],
            activeSlideIndex: 0,
            showAnswerIndex: null
        }
    },
    methods: {
        getShortMonth(unix) {
            const moment = new Date(unix);
            const month = moment.toLocaleString('nl-NL', { month: 'long' })
            return month.substr(0, 3).toUpperCase()
        },
        getDay(unix) {
            const moment = new Date(unix);
            return moment.toLocaleString('nl-NL', { day: 'numeric' })
        },
        getPhaseState(phase) {
            const now = Date.now();

            if(new Date(phase.until) < now) return 'completed'
            else if(new Date(phase.from) < now) return 'active'
            else return 'pending'
        },
        handleFollowProject() {
            this.$emit('toggleFollowModal');
        },
        handleSelectFollowOption() {
            this.user.projects.push(this.project)
            localStorage.setItem('user', JSON.stringify(this.user));
        },
        calculateProjectLength(project) {
            if(!project || !project.omgevingsapp.phases || project.omgevingsapp.phases.length == 0) return ''
            const startDate = project.omgevingsapp.phases[0].from;
            const endDate = project.omgevingsapp.phases[project.omgevingsapp.phases.length-1].until;
            const length = (new Date(endDate) - new Date(startDate)) / 1000;
            this.projectLength = parseTimeToString(length)
        },
        onSlideChange() {
            const swiper = this.$refs.aboutSwiper.$el.swiper;
            this.activeSlideIndex = swiper.activeIndex;
        },
        selectPanel(index) {
            const swiper = this.$refs.aboutSwiper.$el.swiper;
            swiper.slideTo(index)
            this.activeSlideIndex = index;
        },
        selectAnswerIndex(index) {
            if(this.showAnswerIndex == index) this.showAnswerIndex = null;
            else this.showAnswerIndex = index;
        }
    },
    computed: {
        flagColor: function() {
            const phases = this.project.omgevingsapp.phases || [];
            const allPhasesCompleted = phases.filter(phase => this.getPhaseState(phase) == 'completed').length == phases.length;
            return allPhasesCompleted ? '#000000' : 'var(--ion-color-step-400)'
        }
    },
    watch: {
        activeSlideIndex: function() {
            const scrollableContainer = this.$refs.categoryButtons.$el;
            let scrollX = 0;
            for(let i = 0; i < this.activeSlideIndex; i++) {
                const button = document.getElementById(`slide-button-${i}`);
                const marginRight = 13;
                scrollX += button.offsetWidth + marginRight;
            }
            scrollableContainer.scrollTo({ left: scrollX, behavior: 'smooth' })
        },
        activePageIndex: function() {
            if(this.activePageIndex !== 1) {
                this.showFollowOptions = false;
                this.showFollowConfiguration = false;
            }
        }
    }
});
</script>
