<template>
    <div class="post text-post">
        <update-header :update="update"></update-header>
        <div class="post-body">
            <div class="text-container">
                <div v-if="update.title" class="post-title">
                    <h4>{{ update.title }}</h4>
                </div>
                <div v-if="update.about" class="post-text">
                    <p>{{ removeLink(update.about) }}</p>
                </div>
            </div>
            <component
                :is="getComponent(update)"
                :update="update">
            </component>
        </div>
    </div>
</template>

<script>
import UpdateHeader from './UpdateHeader.vue';

import LinkUpdate from '@/components/updates/LinkUpdate.vue';
import ImageUpdate from '@/components/updates/ImageUpdate.vue';
import MultipleImagesUpdate from '@/components/updates/MultipleImagesUpdate.vue';
import VideoUpdate from '@/components/updates/VideoUpdate.vue';
import PollUpdate from '@/components/updates/PollUpdate.vue';

import "@/assets/scss/Updates.scss"

export default {
    name: 'Update',
    props: ['update'],
    components: {
        ImageUpdate,
        MultipleImagesUpdate,
        VideoUpdate,
        LinkUpdate,
        PollUpdate,
        UpdateHeader
    },
    methods: {
        getComponent(update) {
            switch(update.type) {
                case'text': {
                    if(update.about.includes('https://')) return 'link-update'
                    return ''
                }
                case'image': {
                    if(update.images.length == 1) return 'image-update'
                    else return 'multiple-images-update'
                }
                case'video': return 'video-update'
                case'poll': return 'poll-update'
            }
        },
        removeLink(text) {
            if(this.componentName !== 'link-update') return text
            const words = text.split(' ');
            const filteredWords = words.filter(word => !word.includes('https://'))
            return filteredWords.join(' ')
        },
    },
    computed: {
        componentName: function() {
            switch(this.update.type) {
                case'text': {
                    if(this.update.about.includes('https://')) return 'link-update'
                    return ''
                }
                case'image': {
                    if(this.update.images.length == 1) return 'image-update'
                    else return 'multiple-images-update'
                }
                case'video': return 'video-update'
                case'poll': return 'poll-update'
                default: return ''
            }
        }
    }

}
</script>

<style lang="scss">

.post {
    border-bottom: 1px solid var(--ion-border-color-2);
    width: 100%;

    .post-body {
        position: relative;

        .text-container {
            display: flex;
            flex-direction: column;
            gap: 2px;
            padding: 0px 22px 14px 22px;

            .post-text {
    
                p {
                    font-size: 15px;
                    line-height: 20px;
                    color: var(--ion-text-color);
                }
            }
    
            .post-title {
                h4 {
                    font-size: 15px !important;
                    line-height: 19px !important;
                }
            }
        }
    }
}

</style>