<template>
    <ion-page>
        <ion-content :fullscreen="true" class="on-boarding-wrapper">
            <div class="logo">
                <img src="/assets/img/Qfact-logo.svg" alt="">
            </div>
            <div class="on-boarding-slider">
                <swiper
                    :slides-per-view="1"
                    :space-between="0"
                    @slideChange="onSlideChange"
                    @progress="handleSlideScroll"
                    @touchStart="handleTouchStart"
                    @touchEnd="handleTouchEnd"
                    :preventClicks="false"
                    :preventClicksPropagation="false"
                    :allowSlideNext="activeSlideIndex !== 2"
                    :allowSlidePrev="activeSlideIndex !== 0"
                    class="swiper"
                    id="onboarding-slider"
                    ref="mySwiper"
                >
                    <swiper-slide class="onboarding-slider">
                        <div class="slide-inner">
                            <img class="onboarding-image" src="assets/img/Onboarding1.png">
                            <h3>Volg het project</h3>
                        </div>
                    </swiper-slide >
                    <swiper-slide class="onboarding-slider">
                        <div class="slide-inner">
                            <img class="onboarding-image" src="assets/img/Onboarding2.png">
                            <h3>Blijf up-to-date</h3>
                        </div>
                    </swiper-slide>
                    <swiper-slide class="onboarding-slider">
                        <div class="slide-inner">
                            <img class="onboarding-image" src="assets/img/Onboarding3.png">
                            <h3>Kom eenvoudig in contact</h3>
                        </div>
                        <ion-button class="btn btn-primary" expand="block"
                                    @click.prevent="this.$router.push(getNextRoute())">
                            Zoek mijn project
                        </ion-button>
                    </swiper-slide>
                </swiper>

                <div class="swiper-navigation" style="filter: url('#sticky')">
                    <div class="bubble" :class="{ active: activeSlideIndex == 0 }" @click="this.changeSlide(0)"></div>
                    <div class="bubble" :class="{ active: activeSlideIndex == 1 }" @click="this.changeSlide(1)"></div>
                    <div class="bubble" :class="{ active: activeSlideIndex == 2 }" @click="this.changeSlide(2)"></div>
                    <div class="active-bubble-container">
                        <svg viewBox="0 0 10 10" class="active-bubble" :class="{ transition: snapSliderProgress }" :style="`--left-percentage: ${sliderProgress * 100}%`">
                            <circle cx="5" cy="5" r="5" fill="var(--ion-color-step-900)"></circle>
                        </svg>
                    </div>
                </div>

                <svg style="display: none;">
                    <defs>
                        <filter id="sticky">
                            <feGaussianBlur in="SourceGraphic" stdDeviation="4" result="blur"></feGaussianBlur>
                            <feColorMatrix in="blur" 
                                result="colormatrix"
                                type="matrix"
                                values="1 0 0 0 0
                                        0 1 0 0 0
                                        0 0 1 0 0
                                        0 0 0 24 -8"/>
                            <feBlend in="SourceGraphic" in2="colormatrix"></feBlend>
                        </filter>
                    </defs>
                </svg>

            </div>
        </ion-content>
    </ion-page>
</template>

<script>
  import { defineComponent } from 'vue';
  import {
    IonContent,
    IonPage,
    IonButton
  } from '@ionic/vue'
import '../assets/scss/OnBoarding.scss'

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';


export default defineComponent({
    name: 'on-boarding',
    components: {
      IonContent,
      IonPage,
      IonButton,
      Swiper,
      SwiperSlide
    },
    data() {
        return  {
            userInteracted: false,
            activeSlideIndex: 0,
            sliderProgress: 0,
            snapSliderProgress: true,
            progressCalculationReduction: 1,
            progressCalculationReducted: 0
        }
    },
    methods: {
        getNextRoute() {
            const { projectId } = this.$route.query;
            if(!projectId) return '/projects'
            else return `/project/${projectId}`
        },
        onSlideChange(e) {
            this.activeSlideIndex = e.activeIndex;
        },
        handleSlideScroll(e, progress) {
            // only set sliderprogress every 3 calls
            if(this.progressCalculationReducted <= this.progressCalculationReduction) return this.progressCalculationReducted ++
            else this.progressCalculationReducted = 0;
            this.sliderProgress = progress;
        },
        handleTouchStart() {
            this.userInteracted = true;
            this.snapSliderProgress = false;
        },
        async handleTouchEnd() {
            this.snapSliderProgress = true;
            await new Promise(r => setTimeout(r, 10))
            const swiper = this.$refs.mySwiper.$el.swiper;
            this.sliderProgress = 0.5 * swiper.activeIndex;
        },
        changeSlide(index) {
            const swiper = this.$refs.mySwiper.$el.swiper;
            swiper.slideTo(index, 500)
        }
    },
    created() {
        const hasVisited = JSON.parse(localStorage.getItem('hasVisited'))
        if(!hasVisited) return
        this.$router.push('/projects')
    },
    async mounted() {
        const swiper = this.$refs.mySwiper.$el.swiper;
        if(swiper.activeIndex == 0) await new Promise(r => setTimeout(r, 1000))

        const autoSlide = setInterval(async () => {
            if(this.userInteracted || swiper.activeIndex == 2) return clearInterval(autoSlide)

            swiper.slideNext(500);
            this.activeSlideIndex = swiper.activeIndex;
        }, 2500)

        localStorage.setItem('hasVisited', 'true')
    }
});
</script>
