import gql from 'graphql-tag';

export const TICKETS = gql`
    query tickets($where: JSON) {
        tickets(where: $where) {
            id
            projectId
            organisationId
            ticketNumber
            users {
                id
            }
            messages {
                id
                ticketId
                content
                createdAt
                source
                userId
                metaData
            }
            createdAt
            status
        }
    }
`

export const CREATE_TICKET = gql`
    mutation createTicket($projectId: String!, $organisationId: String!, $content: String!, $userId: String!) {
        ticket_created(projectId: $projectId, organisationId: $organisationId, content: $content, userId: $userId) {
            id
            ticketNumber
            projectId
            organisationId
            createdAt
            status
            users {
                id
            }
            messages {
                id
                ticketId
                content
                createdAt
                source
            }
        }
    }
`

export const CREATE_MESSAGE = gql`
    mutation createMessage($ticketId: String!, $projectId: String!, $content: JSON!, $userId: String!, $metaData: JSON) {
        message_created(ticketId: $ticketId, projectId: $projectId, content: $content, userId: $userId, source: "app", metaData: $metaData) {
            id
            ticketId
            content
            createdAt
            source
            metaData
        }
    }
`;
