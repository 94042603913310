<template>
    <ion-page>
        <ion-header class="ion-no-border">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-back-button default-href="home"></ion-back-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content :fullscreen="true" class="ion-padding-start ion-padding-end">
            <div class="login-wrapper d-flex ion-justify-content-between flex-column">
                <div class="head-section">
                    <div class="section-header">
                        <h1>Laten we beginnen</h1>
                        <p>Vul je e-mailadres in</p>
                    </div>
                    <form>
                        <ion-item class="custom-input mb-27" lines="none">
                            <ion-label position="floating" color="none">E-mail</ion-label>
                            <ion-input></ion-input>
                        </ion-item>
                        <ion-button expand="block" class="btn btn-primary"
                                    @click.prevent="this.$router.push('/project-send-messages')">Inloggen
                        </ion-button>
                        <span>of</span>
                        <ion-button expand="block" class="btn btn-white mb-27">
                            <img src="../assets/images/Facebook.svg"/>
                            <span>Connect met Facebook</span>
                        </ion-button>
                        <ion-button expand="block" class=" btn btn-white">
                            <img src="../assets/images/Google.svg"/>
                            <span>Connect met Google</span>
                        </ion-button>
                    </form>
                </div>
                <div class="section-footer">
                    <p class="">Door onze app te te gebruiken ga je akkoord met onze
                        <router-link to="/profile">Gebruikersvoorwaarden</router-link>
                        en
                        <router-link to="/chat-box">Privacy
                            Policy
                        </router-link>
                    </p>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
  import { defineComponent } from 'vue';
  import {
    IonHeader,
    IonToolbar,
    IonContent,
    IonPage,
    IonButtons,
    IonButton,
    IonBackButton,
    IonItem,
    IonLabel,
    IonInput,
  } from '@ionic/vue'
  import "../assets/scss/Login.scss";

  export default defineComponent({
    name: 'login',
    components: {
      IonHeader,
      IonToolbar,
      IonContent,
      IonPage,
      IonButton,
      IonButtons,
      IonBackButton,
      IonItem,
      IonLabel,
      IonInput,
    },
    data() {
      return {}
    }
  });
</script>
