<template>
    <div class="single-image-post">
        <div class="post-body">
            <div class="post-image">
                <img :src="update.images[0].path" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import {
} from '@ionic/vue';

export default {
    name: 'image-update',
    props: ['update']
}
</script>

<style lang="scss" scoped>


.post-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

    

</style>