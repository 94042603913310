<template>
    <div class="app-wrapper">
        <router-view></router-view>
    </div>
</template>

<script>
// import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';


export default defineComponent({
    name: 'App',
    components: {
    },
    data() {
        return {
            usingApp: false,
            selectedPage: 'updates',
            projectId: '',
            project: {},
            loading: false
        }
    },
    created() {
        var android = navigator.userAgent.match(/Android/i) != null;    // Check if using Android
        var iPad = navigator.userAgent.match(/iPad/i) != null;          // Check if using an iPad
        var iPhone = navigator.userAgent.match(/iPhone/i) != null;
        if(android || iPad || iPhone) this.usingApp = true;
    }
});
</script>

<style lang="scss" scoped>

.app-wrapper {
    height: 100%;
}

</style>
