<template>
    <div class="wrapper">
        <div class="messages-wrapper">
            <p style="color:red;">{{ error }}</p>
            <p style="color:green;">{{ message }}</p>
        </div>
        
        <div v-if="jwt && user" class="logout-btn">
            <div v-if="showLogoutBtn">
                <ion-button class="btn btn-grey logout" @click="loggedout" expand="block">Uitloggen</ion-button>
            </div>
        </div>
        <div v-else class="login-wrapper">
            <input class="email-input-bar" type="text" :placeholder="user.email ? user.email : 'E-mail adres'" v-model="email">

            <br>

            <ion-button :disabled="!email" class="btn btn-primary" @click="verifyEmail" expand="block">Email verifieren</ion-button>
        </div>

        <slot v-if="jwt && user" />
    </div>
</template>

<script>
import gql from 'graphql-tag'

export default {
    name: 'Login',
    data() {
        return {
            jwt: localStorage.getItem('jwt') || null,
            user: JSON.parse(localStorage.getItem('user')) || null,
            email: '',
            error: '',
            message: ''
        }
    },
    props: {
        showLogoutBtn: {
            type: Boolean
        }
    },
    methods: {
        verifyEmail() {
            const valid = this.validateEmail(this.email)

            if (!valid) return this.error = 'het Email adres is niet valide!'

            this.$apollo.mutate({
                mutation: gql`
                    mutation {
                        user_passwordless_login(email: "${this.email}", location: "${this.$route.fullPath}") {
                            email
                            firstName
                            lastName
                        }
                    }
                `
            })
            .then(() => {
                this.message = `Je email staat klaar om te verifiëren, zie je mail.`
                this.error = ``
                
                const jwtCheck = setInterval(() => {
                    this.jwt = localStorage.getItem('jwt') || null
                    this.user = JSON.parse(localStorage.getItem('user')) || null
                    if (this.jwt && this.user) {
                        this.$emit('loggedin', { jwt: this.jwt, user: JSON.parse(this.user) })
                        clearInterval(jwtCheck)
                        this.message = ``
                    }
                }, 1000);
            })
            .catch(() => this.error = `Woeps! Er is iets niet goed gegaan, probeer het later nog eens.`)
        },
        validateEmail() {
            return true //this.email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        },
        loggedout() {
            localStorage.removeItem('jwt')
            this.jwt = null
            this.user = null
            this.$emit('loggedin', false)
        }
    },
    mounted() {
        if (this.jwt && this.user) {
            this.$emit('loggedin', { jwt: this.jwt, user: this.user })
        }
    },
    watch: {
        logout() {
            console.log('logging out');
            this.loggedout()
        }
    }
}
</script>

<style scoepd lang="scss">

    .email-input-bar {
        display: flex;
        background: #f0f0f0;
        border-radius: 14px;
        height: 60px;
        width: 100%;
        align-items: center;
        padding: 0 10px;
        margin: 15px 0 0 0;
        border: none;
    }

    .logout-btn {
        width: 100%;
    }

    .btn > .logout {
        --background: var(--ion-color-danger)!important;
    }

    .wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        .messages-wrapper {
            text-align: center;
            height: 40px;
        }

        .login-wrapper {
            width: 100%;
        }

    }

</style>