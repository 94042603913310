<template>
    <div class="video-post">
        <div v-if="!update.videoLink" class="video-wrapper d-flex ion-justify-content-center ion-align-items-center">
            <video controls ref="video" preload="metadata"
                    poster="https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-HD.jpg">
                <source src="https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-720p.mp4"
                        type="video/mp4">
            </video>

            <div class="play-button d-flex ion-justify-content-center ion-align-items-center ion-hide-sm-down"
                    ref="playButton"
                    @click.prevent="togglePlay">
                <!-- SVG Play Button -->
                <svg width="27" height="34" viewBox="0 0 27 34" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                    <path d="M24.4964 15.7663C25.3866 16.3576 25.3904 17.6637 24.5037 18.2603L4.44114 31.7575C3.44638 32.4267 2.10736 31.7162 2.10385 30.5173L2.02518 3.64059C2.02167 2.44169 3.35651 1.72337 4.35516 2.38675L24.4964 15.7663Z"
                            stroke="#F3F3F3" stroke-width="3"/>
                </svg>
            </div>
        </div>
        <iframe v-else class="video-iframe" :src="update.videoLink" frameborder="0" allowfullscreen></iframe>
    </div>
</template>

<script>
import { getElapsedTime } from '@/helpers/utils.js';
import {
} from '@ionic/vue';

export default {
    name: 'text-update',
    props: ['update'],
    methods: {
        togglePlay() {
            const video = this.$refs.video;

            if (video.paused || video.ended) {
                video.play();
            } else {
                video.pause();
            }
        },
        getElapsedTime(time) {
            return getElapsedTime(time)
        }
    },
    mounted() {
        if(this.update.videoLink) return

        const playButton = this.$refs.playButton;
        const video = this.$refs.video;

        video.addEventListener("playing", function () {
            playButton.style.opacity = 0;
        });

        video.addEventListener("pause", function () {
            playButton.style.opacity = 1;
        });
    }
}
</script>

<style lang="scss" scoped>
.video-post {
    position: relative;
    aspect-ratio: 16/9;

    iframe {
        position: absolute;
        width: 100%;
        aspect-ratio: 16/9;
    }
    
    .video-wrapper {
        position: relative;
        width: 100%;
        height: 100%;

        video {
            width: 100%;
            height: 100%;
        }

        .play-button {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            cursor: pointer;
            pointer-events: auto;
            width: 82px;
            height: 82px;
            border-radius: 50%;
            margin: auto;
            background: rgba(161, 161, 162, 0.6);
            backdrop-filter: blur(2px);

            svg {
                pointer-events: none;
                opacity: 0.9;
                backdrop-filter: blur(0px);
            }
        }
    }
}

</style>