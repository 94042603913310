<template>
    <div>
        <div class="project-wrapper">
            <BottomDrawer :project="project">
                <div class="user-info">
                    <p class="ion-text-center">Om u op de hoogte te kunnen blijven houden hebben we wat gegevens
                        nodig.</p>
                    <form>
                        <ion-item class="custom-input mb-12" lines="none">
                            <ion-label position="floating" color="none">Voor- en achternaam</ion-label>
                            <ion-input v-model="user.fullName"></ion-input>
                        </ion-item>
                        <ion-item v-if="selectedFollowOptions.includes('email')" class="custom-input mb-12" lines="none">
                            <ion-label position="floating" color="none">E-mail</ion-label>
                            <ion-input v-model="user.email"></ion-input>
                        </ion-item>
                        <ion-item v-if="selectedFollowOptions.includes('sms')" class="custom-input mb-12" lines="none">
                            <ion-label position="floating" color="none">Telefoonnummer</ion-label>
                            <ion-input v-model="user.phone"></ion-input>
                        </ion-item>

                        <p class="mb-12 ion-text-center">Mijn relatie tot het project</p>

                        <div class="project-relationship d-flex ion-wrap">
                            <ion-chip class="ion-justify-content-center"
                                      v-for="(relation,index) in projectRelationship"
                                      :key="index" :class="{active: isActive === index}"
                                      @click="selectRelation(index)">
                                <ion-label>{{ relation.name }}</ion-label>
                            </ion-chip>
                        </div>

                        <ion-button expand="block" class="btn btn-primary" :disabled="!formCompleted"
                                    @click.prevent="handleSubmitForm">Klaar
                        </ion-button>
                    </form>
                </div>
            </BottomDrawer>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { IonButton, IonLabel, IonChip, IonInput,IonItem } from '@ionic/vue';
import BottomDrawer from '@/components/BottomDrawer.vue';
import "@/assets/scss/ProjectUserRelationship.scss";
import "@/assets/scss/AppHeader.scss";


export default defineComponent({
    name: 'ProjectUserRelationship',
    components: {IonButton, IonLabel, IonChip, IonInput,IonItem, BottomDrawer},
    props: ['project', 'selectedFollowOptions'],
    methods: {
        selectRelation(isActive) {
            this.isActive = isActive;
            this.relation = this.projectRelationship[isActive].name;
        },
        handleSubmitForm() {
            this.$emit('formCompleted', { fullName: this.user.fullName, email: this.user.email.toLowerCase(), phone: this.user.phone, relation: this.relation})
        }
    },
    data() {
        const projectRelationship = [
            {
            id: 1,
            name: 'Bewoner'
            },
            {
            id: 2,
            name: 'Weggebruiker'
            },
            {
            id: 3,
            name: 'Bedrijf'
            },
            {
            id: 4,
            name: 'Passant'
            },
        ];

        return {
            projectRelationship,
            isActive: null,
            relation: '',
            user: JSON.parse(localStorage.getItem('user')) || {
                fullName: '',
                email: '',
                phone: null
            }
        };
    },
    computed: {
        formCompleted: function() {
            const mail = this.selectedFollowOptions.includes('email') ? Boolean(this.user.email) : true;
            const sms = this.selectedFollowOptions.includes('sms') ? Boolean(this.user.phone) : true;
            if(!this.user.fullName || !mail || !sms || !this.relation) return false
            else return true
        }
    }
});
</script>
