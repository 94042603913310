// pass the amount of milliseconds (unix) you want to convert to seconds, days, weeks...
export const getElapsedTime = (time) => {

    const now = Date.now();
    const elapsedSeconds = (now - time) / 1000;

    return parseTimeToString(elapsedSeconds, time);
}

export const getChatTime = (time) => {

    const now = Date.now();
    const elapsedSeconds = (now - time) / 1000;

    const minute = 60;
    const hour = minute * 60;
    const day = hour * 24;
    const year = day * 365;

    const dayOptions = { month: 'long', day: 'numeric' };

    const date = new Date(parseInt(time));

    if (elapsedSeconds < day) {
        return 'vandaag'
    } else if (elapsedSeconds < day*2) {
        return 'gisteren'
    } else if (elapsedSeconds < year) {
        return date.toLocaleDateString("nl-NL", dayOptions)
    } else if (elapsedSeconds > year) {
        let yearsAgo = new Date(now).getFullYear() - (date.getFullYear() - 1)
        return `${yearsAgo} jaar geleden`
    }
}

export const userInitials = (firstName, lastName) => {
    if (!firstName && !lastName) return '';
    const first = firstName ? firstName.charAt(0).toUpperCase() : '';
    const last = lastName ? lastName.charAt(0).toUpperCase() : '';
    return `${first}${last}`;
};

// parse elapsed seconds (unix / 1000) to a string
export const parseTimeToString = (elapsedSeconds, dateUnix) => {
    const minute = 60;
    const hour = minute * 60;
    const day = hour * 24;

    const elapsedDays = Math.round(elapsedSeconds / day);

    if(elapsedDays === 0) {
        if(elapsedSeconds < minute)
        return `zojuist`
        else if(elapsedSeconds < hour)
            return `${Math.round(elapsedSeconds / minute)} min`
        else if(elapsedSeconds < day)
            return `${Math.round(elapsedSeconds / hour)} uur`
    }

    else if(elapsedDays <= 2) {
        const format = new Intl.RelativeTimeFormat('nl-NL', { style: 'long', numeric: 'auto' });
        return format.format(-elapsedDays, 'days')
    }

    else return new Date(dateUnix).toLocaleString('nl-NL', { day: 'numeric', month: 'long', year: 'numeric' })
}