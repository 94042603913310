import { createRouter, createWebHistory } from '@ionic/vue-router';
import TabsPage from '../views/TabsPage.vue'
import Login from "../views/Login";
import Verify from "../views/Verify";
import ProjectSendMessages from "../views/ProjectSendMessages";
import ProjectUserRelationship from "../views/ProjectUserRelationship";
import OnBoarding from "../views/OnBoarding";
import BottomDrawer from "../components/BottomDrawer";

const routes = [
  {
    path: '/',
    redirect: '/on-boarding'
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/verify/:email/:loginToken*',
    component: Verify
  },
  {
    path: '/project-send-messages',
    component: ProjectSendMessages
  },
  {
    path: '/project-user-relationship',
    component: ProjectUserRelationship
  },
  {
    path: '/bottom-drawer',
    component: BottomDrawer,
  },
  {
    path: '/on-boarding',
    component: OnBoarding,
  },
  {
    path: '/projects',
    component: () => import('@/views/Information.vue')
  },
  {
    path: '/projects/:id',
    component: TabsPage
  },
  {
    path: '/projects/:id/chat-box/:ticketId?',
    component: () => import('@/views/SocketChatBox.vue')
  },
  {
    path: '/profile',
    component: () => import('@/views/Profile.vue')
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router
