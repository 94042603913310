<template>
    <BottomDrawer :project="project">
        <div class="notification-list">
            <p class="ion-text-center">Hoe wilt u op de hoogte blijven van belangrijke berichten voor
                <span style="color: #2b2b2b">{{ project.name }}</span>?
            </p>

            <div class="buttons-wrapper">
                <label class="custom-button primary" :class="{ selected: selectedOptions.includes('sms') }" for="sms">
                    <input id="sms" type="checkbox" @click="handleSelectOption('sms')">
                    <svg class="option-icon" viewBox="0 0 512 512"><path d="M427.68 351.43C402 320 383.87 304 383.87 217.35 383.87 138 343.35 109.73 310 96c-4.43-1.82-8.6-6-9.95-10.55C294.2 65.54 277.8 48 256 48s-38.21 17.55-44 37.47c-1.35 4.6-5.52 8.71-9.95 10.53-33.39 13.75-73.87 41.92-73.87 121.35C128.13 304 110 320 84.32 351.43 73.68 364.45 83 384 101.61 384h308.88c18.51 0 27.77-19.61 17.19-32.57zM320 384v16a64 64 0 01-128 0v-16" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
                    <span>SMS berichten</span>
                </label>

                <label class="custom-button secondary" :class="{ selected: selectedOptions.includes('email') }" for="email">
                    <input id="email" type="checkbox" @click="handleSelectOption('email')">
                    <svg class="option-icon" :class="{ show: selectedOptions.includes('email') }" viewBox="0 0 512 512"><rect x="48" y="96" width="416" height="320" rx="40" ry="40" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M112 160l144 112 144-112"/></svg>
                    E-mails
                </label>

                <label class="custom-button tertiary" :class="{ selected: selectedOptions.includes('notifications') }" for="notifications">
                    <input id="notifications" type="checkbox" @click="handleSelectOption('notifications')">
                    <svg class="option-icon" :class="{ show: selectedOptions.includes('notifications') }" viewBox="0 0 512 512"><rect x="128" y="16" width="256" height="480" rx="48" ry="48" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path d="M176 16h24a8 8 0 018 8h0a16 16 0 0016 16h64a16 16 0 0016-16h0a8 8 0 018-8h24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
                    App Notificaties
                </label>
            </div>

            <ion-button v-if="selectedOptions.length == 0" class="btn btn-light" expand="block" @click.prevent="handleCancelFollow">
                Annuleren
            </ion-button>
            <ion-button v-else class="btn btn-light" expand="block" @click.prevent="handleNextStep">
                Volgende
            </ion-button>
        </div>
    </BottomDrawer>
</template>

<script>
import { defineComponent } from 'vue';
import { IonButton } from '@ionic/vue';
import BottomDrawer from '@/components/BottomDrawer.vue';
import "@/assets/scss/ProjectSendMessages.scss";
import "@/assets/scss/AppHeader.scss";


export default defineComponent({
    name: 'project-send-messages',
    components: {IonButton, BottomDrawer},
    props: ['project'],
    data() {
        return {
            selectedOptions: []
        }
    },
    methods: {
        handleSelectOption(option) {
            const optionIndex = this.selectedOptions.indexOf(option)

            if(optionIndex >= 0) this.selectedOptions.splice(optionIndex, 1)
            else this.selectedOptions.push(option)
            console.log(this.selectedOptions)
        },
        handleCancelFollow() {
            this.$emit('cancelFollow')
        },
        handleNextStep() {
            this.$emit('nextStep', [ ...this.selectedOptions ])
        }
    }
});
</script>
