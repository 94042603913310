<template>
    <div v-if="update" class="post-header">
        <div class="left">
            <div class="post-profile feed-bg-gradient d-flex ion-justify-content-center ion-align-items-center">
                <ion-avatar slot="start">
                    <img v-if="update.createdUser && update.createdUser.avatar" :src="update.createdUser.avatar">
                    <img v-else src="@/assets/images/unsplash.png">
                </ion-avatar>
            </div>
            <ion-label>
                <h6 v-if="update.createdUser">{{ getFullName(update.createdUser.firstName, update.createdUser.lastName) }}</h6>
                <h6 v-else>...</h6>
                <p class="d-flex ion-align-items-center">
                    <span v-if="update.createdUser && update.createdUser.role" >{{ update.createdUser.role }}</span>
                    <span>{{ getElapsedTime(update.publishDate) }}</span>
                </p>
            </ion-label>
        </div>
    </div>
</template>

<script>
import { getElapsedTime } from '@/helpers/utils.js';

import {
    IonAvatar,
    IonLabel
} from '@ionic/vue';

export default {
    name: 'update-header',
    components: {
        IonLabel,
        IonAvatar
    },
    props: ['update'],
    methods: {
        getFullName(firstName, lastName) {
            return `${firstName} ${lastName}`
        },
        getElapsedTime(time) {
            return getElapsedTime(time)
        }
    }
}
</script>

<style lang="scss" scoped>

.post-header {
    display: flex;
    justify-content: space-between;
    padding: 12px 18px;

    .left {
        display: flex;
        align-items: center;

        .post-profile {
            height: 40px;
            width: 40px;
            border-radius: 50%;
            margin-right: 8px;
        
            ion-avatar {
                height: 36px;
                width: 36px;
                border: 2px solid white;
                --color: var(--ion-color-primary-shade);
            }
        }
        
        ion-label {
            h6 {
                font-weight: 700 !important;
                font-size: 10px !important;
                margin-bottom: -2px;
            }
        
            span {
                font-weight: 500;
                font-size: 10px;
                line-height: 130.5%;
                color: var(--ion-color-step-500);
                position: relative;
        
                &:nth-of-type(2) {
                    padding-left: 12px;
        
                    &:before {
                        content: "";
                        width: 2px;
                        height: 2px;
                        background: var(--ion-color-step-500);
                        position: absolute;
                        left: 5px;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        border-radius: 50%;
                    }
                }
            }
        }

    }

    .right {
        ion-button {
            --background: none;
            --box-shadow: none;
            margin: 0;
        }
    }

}

</style>