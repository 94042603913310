<template>
    <div v-if="project" class="page updates-page" id="content">
        <div class="project-wrapper updates">
            <div v-if="updates && updates.length > 0" class="wrapper">
                <div v-if="pinnedUpdates.length > 0" class="story-actions-wrapper d-flex ion-padding-horizontal">
                    <div v-for="pinnedUpdate in pinnedUpdates" :key="pinnedUpdate.id" @click="viewPinnedUpdate(pinnedUpdate.id)" class="feed-actions-inner">
                        <div class="feed-action d-flex ion-justify-content-center ion-align-items-center">
                            <ion-button>
                                <img src="@/assets/images/delete.svg" alt="">
                            </ion-button>
                        </div>
                    </div>
                </div>
                
                <update
                    v-for="update in reversedUpdates"
                    :key="update.id"
                    :id="update.id"
                    :update="update">
                </update>

            </div>

            <div class="center" v-else-if="!loading">
                <p class="no-posts-message">Er zijn nog geen berichten geplaatst</p>
            </div>
        </div>
    </div>
</template>

<script>
import Update from '@/components/updates/Update.vue';

import { defineComponent } from 'vue';
import {
    // IonPage
    // IonContent,
    IonButton,
} from '@ionic/vue';
// import BottomDrawer from '@/components/BottomDrawer.vue';
import "@/assets/scss/AppHeader.scss";
import "@/assets/scss/Updates.scss"

export default defineComponent({
    name: 'Updates',
    components: {
        // IonPage,
        // IonContent,
        IonButton,
        // BottomDrawer,
        Update
    },
    data() {
        return {
            isActive: null,
            btnIsVisible: false,
            headerIsVisible: true,
            project: {},
            updates: [],
            loading: false
        }
    },
    methods: {
        getProject(id) {
            if(!id) return
            this.$http({
                method: 'GET',
                url: `${process.env.VUE_APP_BACKEND_URL}/projects/public/${id}`
            })
            .then(result => {
                this.project = result.data;
            })
            .catch(error => {
                console.log(error)
            })
        },
        getUpdates(projectId) {
            if(!projectId) return
            this.loading = true;
            this.$emit('loadingStart')
            this.$http({
                method: 'GET',
                url: `${process.env.VUE_APP_BACKEND_URL}/updates/public?projectId=${projectId}`
            })
            .then(result => {
                this.loading = false;
                this.$emit('loadingEnd')
                this.updates = result.data;
                if(this.updates.length === 0) return this.$emit('noUpdates')
            })
            .catch(error => {
                this.loading = false;
                this.$emit('loadingEnd')
                console.log(error)
            })
        },
        async viewPinnedUpdate(updateId) {
            const update = document.getElementById(updateId);
            const content = document.getElementById('content');
            content.scrollTo({ top: update.offsetTop, behavior: 'smooth' })
        }
    },
    computed: {
        pinnedUpdates: function() {
            if(!this.updates) return []
            return this.updates.filter(update => update.pinned)
        },
        reversedUpdates: function() {
            const updates = JSON.parse(JSON.stringify(this.updates))
            return updates.reverse()
        }
    },
    created() {
        const projectId = this.$route.params.id;
        this.getProject(projectId);
        this.getUpdates(projectId);
    }
});
</script>

<style lang="scss" scoped>
</style>
