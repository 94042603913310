<template>
    <div class="page">
        <div class="project-wrapper contact-us">
            <div v-if="project && project.omgevingsapp" class="contact-info">
                <div>
                    <h5>Contact opnemen</h5>
                    <p>Als je vragen hebt neem dan contact op via de chat of bel een van de contactpersonen.</p>
                    <ion-list>
                        <ion-item v-if="project.omgevingsapp.email" lines="none">
                            <ion-avatar slot="start">
                                <!-- <img src="@/assets/images/contact-profile.png"> -->
                                <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><path fill="currentColor" d="M424 80H88a56.06 56.06 0 00-56 56v240a56.06 56.06 0 0056 56h336a56.06 56.06 0 0056-56V136a56.06 56.06 0 00-56-56zm-14.18 92.63l-144 112a16 16 0 01-19.64 0l-144-112a16 16 0 1119.64-25.26L256 251.73l134.18-104.36a16 16 0 0119.64 25.26z"/></svg>
                            </ion-avatar>
                            <ion-label>
                                <h4>Email versturen?</h4>
                                <a :href="`mailto:${this.project.omgevingsapp.email}?subject=${this.project.name}`" class="contact-phone">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><path fill="currentColor" d="M424 80H88a56.06 56.06 0 00-56 56v240a56.06 56.06 0 0056 56h336a56.06 56.06 0 0056-56V136a56.06 56.06 0 00-56-56zm-14.18 92.63l-144 112a16 16 0 01-19.64 0l-144-112a16 16 0 1119.64-25.26L256 251.73l134.18-104.36a16 16 0 0119.64 25.26z"/></svg>
                                    {{ project.omgevingsapp.email }}
                                </a>
                            </ion-label>
                        </ion-item>
                        <ion-item v-for="contact in project.omgevingsapp.contactPersons" :key="contact.name" lines="none">
                            <ion-avatar slot="start">
                                <!-- <img src="@/assets/images/contact-profile.png"> -->
                                <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><path fill="currentColor" d="M332.64 64.58C313.18 43.57 286 32 256 32c-30.16 0-57.43 11.5-76.8 32.38-19.58 21.11-29.12 49.8-26.88 80.78C156.76 206.28 203.27 256 256 256s99.16-49.71 103.67-110.82c2.27-30.7-7.33-59.33-27.03-80.6zM432 480H80a31 31 0 01-24.2-11.13c-6.5-7.77-9.12-18.38-7.18-29.11C57.06 392.94 83.4 353.61 124.8 326c36.78-24.51 83.37-38 131.2-38s94.42 13.5 131.2 38c41.4 27.6 67.74 66.93 76.18 113.75 1.94 10.73-.68 21.34-7.18 29.11A31 31 0 01432 480z"/></svg>
                            </ion-avatar>
                            <ion-label>
                                <h4>{{ contact.name }}</h4>
                                <span class="d-flex ion-align-items-center">
                                    {{ contact.role }}
                                </span>
                                <a v-if="contact.phone" :href="`tel:${contact.phone}`" class="contact-phone">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.5317 12.4724C15.5208 16.4604 16.4258 11.8467 18.9656 14.3848C21.4143 16.8328 22.8216 17.3232 19.7192 20.4247C19.3306 20.737 16.8616 24.4943 8.1846 15.8197C-0.493478 7.144 3.26158 4.67244 3.57397 4.28395C6.68387 1.17385 7.16586 2.58938 9.61449 5.03733C12.1544 7.5765 7.54266 8.48441 11.5317 12.4724Z" fill="currentColor"/></svg>
                                    {{ contact.phone }}
                                </a>
                            </ion-label>
                        </ion-item>
                    </ion-list>
                </div>

                <div v-if="tickets.length">
                    <h5>Alle chats</h5>
                    <div class="tickets-container">
                        <div v-for="ticket in tickets" :key="ticket.id" class="ticket" @click="goToTicket(ticket)">
                            <div class="left">
                                <p class="last-message">Laatste bericht</p>
                                <p class="content">{{ ticket.messages[ticket.messages.length-1].content }}</p>
                            </div>
                            <div class="right">
                                <p class="ticket-number">{{ ticket.ticketNumber }}</p>
                                <p class="date">{{ getChatTime(ticket.createdAt) }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                
                <ion-button class="btn btn-primary start-chat-button" expand="block" @click="startChat">Chat starten</ion-button>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import { TICKETS } from '../../helpers/graphql';
import { getElapsedTime, getChatTime } from '../../helpers/utils';
import { IonAvatar, IonLabel, IonList, IonItem,IonButton } from '@ionic/vue';
import '@/assets/scss/AppHeader.scss';
import '@/assets/scss/Contact.scss';

export default defineComponent({
    name: 'Contact',
    components: {
        IonAvatar, 
        IonLabel, 
        IonList, 
        IonItem,
        IonButton
    },
    data() {
        return {
            tickets: [],
            loggedin: null
        }
    },
    props: {
        activePageIndex: {
            type: Number
        },
        project: {
            type: Object
        }
    },
    methods: {
        getTickets(jwt, user) {
            if (jwt && user) {
                this.$apollo.query({
                    query: TICKETS,
                    variables: { 
                        where: { 
                            AND: [
                                { 
                                    projectId: this.$route.params.id
                                }, 
                                { 
                                    users__some_id: user.id
                                }
                            ] 
                        } 
                    },
                    context: { headers: { authorization: `Bearer ${jwt}` } },
                    fetchPolicy: 'no-cache'
                })
                .then(result => {
                    this.tickets = result.data.tickets
                })
                .catch(error => {
                    localStorage.removeItem('jwt')
                    this.loggedin = null
                    console.log(error)
                })
            }
        },
        getDate(createdAt) {
            return getElapsedTime(parseInt(createdAt))
        },
        getChatTime(time) {
            return getChatTime(time)
        },
        goToTicket(ticket) {
            this.$router.push(`/projects/${this.project.id}/chat-box/${ticket.id}`)
        },
        startChat() {
            if(!localStorage.getItem('jwt')) return this.$emit('profilePage')
            this.$router.push(`/projects/${this.project?.id}/chat-box/`)
        }
    },
    mounted() {
        const jwt = localStorage.getItem('jwt')
        const user = JSON.parse(localStorage.getItem('user'))
        this.getTickets(jwt, user)
    }
});
</script>

<style lang="scss">
    .contact-info {
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .contact-btns {
        height: max-content;

        display: flex;
        flex-direction: column;

        p {
            text-align: center;
        }

        .email-input-bar {
            display: flex;
            background: #f0f0f0;
            border-radius: 14px;
            height: 60px;
            width: 100%;
            align-items: center;
            padding: 0 10px;
            margin: 15px 0 0 0;
            border: none;
        }
    }

    .login-wrapper {

    }

    .tickets-wrapper {
        width: 100%;
        height: 213px;
        overflow-y: scroll;

        .ticket-wrapper {
            margin: 10px 0;
        }
    }
</style>
