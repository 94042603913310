<template>
    <div class="page">
        <ion-header class="ion-no-border profile-header ion-padding-start ion-padding-end">
            <ion-toolbar class="">
                <ion-buttons slot="start">
                    <ion-button>
                        <img src="../assets/images/Setting.svg" alt="">
                    </ion-button>
                </ion-buttons>

                <ion-buttons slot="end">
                    <ion-button>
                        <img src="../assets/images/Notification.svg" alt="">
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content :fullscreen="true" class="ion-padding-start ion-padding-end profile-wrapper">
            <div v-if="loggedin" class="profile-details">
                <ion-avatar>
                    <h1 class="avatar-text">
                        {{ userInitials }}
                    </h1>
                </ion-avatar>
                <h1>{{ username || user.email }}</h1>
                <p>
                    <img src="../assets/images/Location.svg" alt="">
                    <span>Veenendaal, Utrecht</span>
                </p>
            </div>

            <div v-else>
                <center>
                    <p>Vul je e-mailadres in en en verifieer deze door op de link in je mailbox te klikken. Daarna kun je een chat starten.</p>
                </center>
            </div>

            <div v-if="loggedin" class="profile-actions">
                <ion-item lines="none" class="ion-no-padding">
                    <ion-label>Notificaties</ion-label>
                    <ion-toggle></ion-toggle>
                </ion-item>
                <ion-item lines="none" class="ion-no-padding">
                    <ion-label>Projecten</ion-label>
                    <ion-toggle></ion-toggle>
                </ion-item>
            </div>

            <login :style="loggedin ? 'margin-top: calc(100vh - 615px);' : 'margin-top: calc(100vh - 400px);'" @loggedin="handleLoggedIn" showLogoutBtn />

        </ion-content>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import {
    IonContent,
    IonButton,
    IonAvatar,
    IonToggle,
    IonItem,
    IonLabel,
    IonHeader,
    IonToolbar,
    IonButtons
} from '@ionic/vue';
import '../assets/scss/Profile.scss';

import { userInitials } from "../helpers/utils";
import Login from "../components/Login";

export default defineComponent({
    name: 'Profile',
    components: {
        IonContent,
        IonButton,
        IonAvatar,
        IonToggle,
        IonItem,
        IonLabel,
        IonHeader,
        IonToolbar,
        IonButtons,
        Login
    },
    data() {
        return {
            loggedin: false,
            user: null
        }
    },
    methods: {
        handleLoggedIn(e) {
            this.loggedin = e
        }
    },
    computed: {
        userInitials() {
            let { firstName, lastName } = this.loggedin.user
            return userInitials(firstName, lastName)
        },
        username() {
            let { firstName, lastName, email } = this.loggedin.user
            if(!firstName && !lastName) return email
            return `${firstName} ${lastName}`
        },
    }
});
</script>


<style scoped lang="scss">

.avatar-text {
    font-weight: 700!important;
    /* letter-spacing: 4px; */
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

</style>