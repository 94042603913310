<template>
    <div class="poll-wrapper">
        <img v-if="updateObject.poll.imagePath" :src="updateObject.poll.imagePath">
        <div class="post-polls">
            <h4>{{ updateObject.poll.name }}</h4>
            <div class="poll-options">
                <div class="option-container" 
                    v-for="(option, index) in pollOptions"
                    :key="option.id" :class="{ active: (selectedOptionIds.includes(option.id) || selectedOptionId == option.id), results: viewPollResults, selected: hasOptionSelected, highest: highestPercentage == option.percentage }"
                    @click="selectPollOption(option.id)"
                    :style="`--percentage: ${ viewPollResults ? option.percentage : '0' }%; --background-transition: .6s; --stagger-delay: .${viewPollResults ? index + 1 : (pollOptions.length-1 - index) + 1}s`"
                >
                    <span class="option-label">{{ option.name }}</span>
                    <div class="percentage-container"></div>
                    <div v-if="option.percentage > 0" class="option-percentage" :class="{ show: viewPollResults }">
                        <span class="percentage-label">{{ Math.round(option.percentage) }}</span>
                    </div>
                </div>
            </div>
            <p :class="{ show: updateObject.poll.multiple && !userHasAnswered }">Er zijn meerdere antwoorden mogelijk</p>
            <div class="poll-result" :class="{ show: pollAnswers > 0 && userHasAnswered }">
                <ion-text>
                    {{ pollAnswers == 0 ? 'Geen antwoorden' : `${pollAnswers} antwoord${pollAnswers > 1 ? 'en' : ''}` }}
                </ion-text>
                <span class="view-results" @click="viewPollResults = !viewPollResults">{{ viewPollResults ? 'Verberg' : 'Bekijk' }} resultaten</span>
            </div>
        </div>
    </div>
</template>

<script>
import { IonText } from '@ionic/vue';
import _ from 'lodash';

export default {
    name: 'poll-update',
    components: {
        IonText
    },
    props: ['update'],
    data() {
        return {
            selectedOptionIds: [],
            selectedOptionId: '',
            user: JSON.parse(localStorage.getItem('user')) || {},
            updateObject: this.update || {},
            viewPollResults: false,
            hasOptionSelected: false,
            highestPercentage: 0
        }
    },
    methods: {
        async selectPollOption(id) {
            if(this.viewPollResults) return
            this.hasOptionSelected = true;
            if(this.updateObject.poll.multiple) this.selectMultiple(id); 
            else this.toggleOption(id);
            await new Promise(r => setTimeout(r, 500))
            this.hasOptionSelected = false
        },
        selectMultiple(id) {
            const indexOfId = this.selectedOptionIds.indexOf(id);
            if(indexOfId < 0) this.selectedOptionIds.push(id);
            else this.selectedOptionIds.splice(indexOfId, 1);

            this.optionSelected()
        },
        optionSelected: _.debounce(function() {
            this.savePollAnswers();
        }, 1000),
        toggleOption(id) {
            if(this.selectedOptionIds.includes(id)) this.selectedOptionIds = [];
            else this.selectedOptionIds = [id];
            this.savePollAnswers();
        },
        savePollAnswers() {
            const data = {
                values: this.selectedOptionIds,
                userId: this.user.id
            }

            this.$http({
                method: 'POST',
                url: `${process.env.VUE_APP_BACKEND_URL}/polls/${this.updateObject.poll.id}/submit`,
                data
            })
            .then(result => {
                this.updateObject.poll = result.data;
            })
            .catch(error => console.log(error))
        }
    },
    computed: {
        pollAnswers: function() {
            const votes = this.updateObject.poll.votes || [];
            return votes.length
        },
        pollOptions: function() {
            const votes = this.updateObject.poll.votes || [];

            const newOptions = this.updateObject.poll.options.map(option => {
                let timesChosen = 0;

                votes.map(vote => {
                    if(vote.values.includes(option.id)) timesChosen++;
                })
                const percentage = Math.round((timesChosen / this.pollAnswers) * 100);
                option.percentage = percentage;

                if(percentage > this.highestPercentage) this.highestPercentage = percentage;

                return option
            })

            return newOptions
        },
        userHasAnswered: function() {
            const votes = this.updateObject.poll.votes || [];
            return votes.map(vote => vote.userId).includes(this.user.id)
        }
    },
    mounted() {
        const votes = this.updateObject.poll.votes || [];
        const userVoteIndex = votes.findIndex(vote => vote.userId == this.user.id)
        if(userVoteIndex < 0) return
        this.selectedOptionIds = this.updateObject.poll.votes[userVoteIndex].values;
    }
}
</script>

<style lang="scss" scoped>

.poll-wrapper {
    img {
        width: 100% !important;
    }

    .post-polls {
        padding: 12px 22px 22px 22px;
        border-top: 1px solid var(--ion-border-color-2);
    
        h4 {
            text-align: center;
            margin-bottom: 16px!important;
            line-height: 20px !important;
        }
    
        p {
            text-align: center;
            margin-block: 6px;
            max-height: 0;
            overflow: hidden;
            transition: .3s ease-in-out;
    
            &.show {
                max-height: 20px;
            }
        }
    
        .poll-options {
            display: flex;
            flex-direction: column;
            gap: 10px;
            overflow: hidden;
    
            .option-container {
                position: relative;
                height: 38px;
                font-weight: 700;
                font-size: 16px;
                color: var(--ion-color-primary-light-shade);
                border: 1px solid var(--ion-border-color-3);
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all .3s var(--stagger-delay), transform .2s ease, background .6s ease calc(var(--stagger-delay) + .1s);
    
                &.selected {
                    --stagger-delay: 0s !important;
                    --background-transition: .3s !important;
                }
    
                &:focus, &:active {
                    transform: scale(0.96);
                }
    
                &.active {
    
                    .option-label {
                        color: var(--ion-color-primary-contrast);
                    }
    
                    .percentage-container {
                        width: 100%;
                        background-color: var(--qlubb-darkblue);
                    }
                }
    
                &.results {
                    background: #e8e8e8;
                    border-color: var(--qlubb-darkblue-light) !important;
    
                    &:focus, &:active {
                        transform: none;
                    }
    
                    &.active {
                        border-color: var(--qlubb-darkblue) !important;
                        
                        .percentage-container {
                            background-color: var(--qlubb-darkblue) !important;
                            opacity: 1;
                        }
                    }
    
                    .option-label {
                        color: #FFFFFF;
                        text-shadow: 0 1px 3px #707070;
                    }
    
                    .percentage-container {
                        background-color: var(--qlubb-darkblue-light);
                        width: var(--percentage) !important;
                        opacity: 0.9;
                    }
                }
    
    
                .option-label {
                    z-index: 1;
                    line-height: 17px;
                    transition: .2s ease-in-out calc(var(--stagger-delay) + .1s);
                }
    
                .percentage-container {
                    position: absolute;
                    height: 100%;
                    left: 0;
                    width: var(--percentage);
                    background-color: var(--qlubb-darkblue-light);
                    z-index: 0;
                    border-radius: 7px;
                    transition: var(--background-transition) ease-out var(--stagger-delay);
                }
    
                .option-percentage {
                    position: absolute;
                    display: flex;
                    gap: 3px;
                    align-items: center;
                    left: -50px;
                    transition: .5s ease-in-out var(--stagger-delay);
    
                    &.show {
                        left: 6px;
                    }
    
                    .people-icon {
                        width: 16px;
                        height: 16px;
                        fill: #FFFFFF;
                    }
    
                    .percentage-label {
                        color: #ffffff;
                        font-size: 14px;
                        text-shadow: 0 1px 2px #4c4c4c;
    
                        &:after {
                            content: "%";
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    
        .poll-result {
            margin-top: 8px;
    
            max-height: 0px;
            overflow: hidden;
            transition: .3s ease-in-out;
    
            &.show {
                max-height: 20px;
            }
    
            ion-text, span {
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
            }
    
            ion-text {
                color: var(--ion-color-step-500);
                margin-right: 10px;
            }
    
            .view-results {
                color: var(--ion-color-primary) !important;
            }
        }
    }
}


</style>