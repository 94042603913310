import { createApp, h } from 'vue'
import App from './App.vue'
import router from './router';
import axios from 'axios'

import { VUE_APP_SOCKET_URL, VUE_APP_GRAPHQL_ENDPOINT } from './config/config'

// apollo client dependencies
import { ApolloClient, InMemoryCache } from '@apollo/client/core'
import { createApolloProvider } from '@vue/apollo-option'

// socket io
// import custom socket io for Vue 3 fix.
import VueSocketIO from './helpers/socketio';
import SocketIO from 'socket.io-client';

import { IonicVue } from '@ionic/vue';
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import '@/theme/variables.scss';

/* Custom css */
import '@/theme/basic.scss';

// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
const apolloClient = new ApolloClient({
  cache,
  uri: VUE_APP_GRAPHQL_ENDPOINT,
  headers: { "application-context": "omgevingsapp" }
})

const apolloProvider = createApolloProvider({ defaultClient: apolloClient })

const socketClient = new VueSocketIO({
  connection: SocketIO(VUE_APP_SOCKET_URL),
  transports : ['polling'],
  allowEIO3: true,
  debug: true,
})

const axiosInstance = axios.create()

const app = createApp({
  render: () => h(App)
})
.use(IonicVue)
.use(router)
.use(apolloProvider)
.use(socketClient);

app.config.globalProperties.$http = axiosInstance

router.isReady().then(() => {
  app.mount('#app');
});
