<template>
    <div class="web-link-post">
        <div class="post-image" @click="go(url)">
            <img :src="update.linkMetaData && update.linkMetaData.image ? update.linkMetaData.image : 'assets/img/feed.png'" alt="">
        </div>
        <a v-if="update.linkMetaData && update.linkMetaData.title" class="post-link" :href="url" target="_blank">
            <p>
                {{ update.linkMetaData.title }}
            </p>
            <a :href="url"  target="_blank">{{ parseLink(url) }}</a>
        </a>
        <div class="post-actions" @click="go(url)">
            <ion-button>
                <span>Bezoeken</span>
            </ion-button>
        </div>
    </div>
</template>

<script>
import {
    IonButton,
} from '@ionic/vue';

export default {
    name: 'text-update',
    components: {
        IonButton
    },
    props: ['update'],
    computed: {
        url: function() {
            const words = this.update.about.split(' ')
            const link = words.filter(word => word.includes('https://'))[0]
            return link
        }
    },
    methods: {
        parseLink(url) {
            return url.split('/').filter(string => string.includes('.'))[0]
        },
        go(to) {
            window.open(to,'_blank');
        }
    }
}
</script>

<style lang="scss" scoped>
.web-link-post {
    .post-link {
        padding: 15px;
        background: #EAEBEF;
        display: block;

        p {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: var(--ion-text-color);
        }

        a {
            font-weight: 500;
            font-size: 10px;
            line-height: 130.5%;
            color: var(--ion-color-step-500) !important;
        }
    }

    .post-image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
    }

    .post-actions {
            position: absolute;
            bottom: 5px;
            right: 15px;

            ion-button {
                --background: rgba(161, 161, 162, 0.4);
                --box-shadow: none;
                backdrop-filter: blur(3px);
                --border-radius: 50px;
                color: #FBFBFB;
                font-weight: 500;
                font-size: 10px;
                line-height: 15px;
                height: 25px;


                img {
                    height: 12px;
                    width: 12px;
                    margin-right: 5px;
                }
            }
        }
}

</style>