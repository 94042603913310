<template>
  <div class="multi-image-post">
    <div class="multiple-image-post">
        <div class="image-grid"
              :class="[css, update.images.length > cells ? 'image-grid-' + cells: 'image-grid-' + update.images.length]">
            <template v-for="(image, i) in update.images" :key="i">
                <a class="image-grid-item" :href="image.path" role="link" @click.prevent="show(i)" v-if="i < cells"
                    :style="backgroundImage(image.path)">
                    <span class="images-more" v-if="i == cells - 1 && update.images.length - cells > 0">+{{ update.images.length - cells}}</span>
                </a>
            </template>
        </div>
    </div>

    <template v-if="index >= 0">
        <ion-content>
            <ion-modal
                    :is-open="isOpenRef"
                    css-class="post-image-modal"
                    canDismiss="true"
                    backdrop-dismiss="true"
                    show-backdrop="true"
                    :presenting-element="$parent.$refs.ionRouterOutlet"
                    @didDismiss="setOpen(false)"

            >
                <swiper @swiper="setSwiperInstance" :modules="modules"
                        :pagination="{type: 'fraction'}" :keyboard="true">
                    <ion-button class="btn btn-transparent btn-close" @click="setOpen(false)">
                        <ion-icon :icon="close"></ion-icon>
                    </ion-button>
                    <swiper-slide v-for="(slide,index) in update.images" :key="index">
                        <img :src="slide.path"/>
                    </swiper-slide>
                </swiper>
            </ion-modal>
        </ion-content>
    </template>
  </div>
</template>

<script>
import { defineComponent,ref } from 'vue';
import { close } from 'ionicons/icons';
import { Pagination,Keyboard } from 'swiper';
import { IonButton,IonModal, IonContent } from '@ionic/vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/scss';
import 'swiper/css/pagination';
import 'swiper/css/keyboard';
import '@ionic/vue/css/ionic-swiper.css';
import '@/assets/scss/MultipleImagesPost.scss';

export default defineComponent({
    name: 'multiple-images-update',
    data() {
        const isOpenRef = ref(false);
        const setOpen = (state) => isOpenRef.value = state;

        return {
            index: -1,
            modules: [Pagination,Keyboard],
            isOpenRef,
            setOpen,
            close
        }
    },
    components: {
        Swiper, SwiperSlide, IonButton,IonModal, IonContent
    },
    props: {
      css: {
        type: String,
        default: () => 'h-250'
      },

      update: {
        type: Object,
        default: () => {}
      }
    },
    methods:{
      setSwiperInstance(swiper) {
        swiper.activeIndex = this.index;
        swiper.update(true);
        console.log(swiper)

      },
      show(i) {
        if (i >= this.update.images.length) i = 0;
        if (i < 0) i = this.update.images.length - 1;

        this.index = i;
        this.setOpen(true);
      },
      backgroundImage(i) {
        return i && i.length > 0 ? `background-image: url('${i}')` : '';
      }
    },
    computed: {
      cells: function() {
        if(this.update.images.length > 4) return 4
        else return 2
      }
    }
  });
</script>

<style lang="scss" scoped>

</style>
