<template>
    <div class="project-header" id="project-header">
        <div class="buttons">
            <div class="button" @click="this.$router.push('/projects')">
                <svg width="10" height="14" fill="none" viewBox="0 0 10 14" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.52087 12.0203L2.47921 7.00033L7.52087 1.97949" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                Projecten
            </div>
            <div class="button" @click="handleFollowProject">
                {{ isFollowing ? 'Volgend' : 'Volgen' }}
            </div>
        </div>
        <h4 class="project-name">{{ project.name }}</h4>
        <div class="project-info">
            <div v-if="initiator">
                <img v-if="initiator.logo" class="organisation-logo" :src="initiator.logo" alt="">
                <span>{{ initiator.name }}</span>
            </div>
            <div v-if="other"><span> | {{ other.name }}</span></div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import '@/assets/scss/ProjectHeader.scss';

export default defineComponent({
    name: 'ProjectHeader',
    props: {
        project: {
            type: Object,
            default: () => {}
        },
        isFollowing: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')) || {},
            initiator: null,
            other: null
        }
    },
    methods: {
        handleFollowProject() {
            this.$emit('toggleFollowProject')
        },
        setData() {
            const project = JSON.parse(JSON.stringify(this.project))
            const initiatorId = project.organisationId;
            if(!initiatorId) {
                this.initiator = project.contractor
                this.other = project.client
                return
            }
            
            if(project.client.id === initiatorId) {
                this.initiator = project.client
                this.other = project.contractor
            }
            else {
                this.initiator = project.contractor
                this.other = project.client
            }
        }
    },
    computed: {
        getProjectCity() {
            if(!this.project) return
            if(!this.project.omgevingsapp) return
            if(!this.project.omgevingsapp.location) return
            return this.project.omgevingsapp.location.city
        },
    },
    created() {
        this.setData()
    },
    watch: {
        project: function() {
            this.setData()
        }
    }
});
</script>
